import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { Member } from '../models';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(
    private router: Router,
    // private member: Member
  ) {}

  canActivate() {
    // if ( this.member.role === 1 ){ //TBM_ROLE.SU ) {
    if ( JSON.parse(localStorage.getItem('user'))?.role == 1){ //TBM_ROLE.SU ) {
      return true;
    }
    let lang = location.pathname.substr(1,2);
    if( lang === 'kr' ) {
      this.router.navigate(['kr/home']);
    } else if ( lang === 'en' ){
      this.router.navigate(['en/home']);
    }
    return false;
  }
}
