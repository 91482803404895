// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page404{
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #eceef0;
  z-index: 10;
}

.error-template{
  position: relative;
  top: calc(50% - 15%);
  height: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h1, h2{
  color: #212935;
  font-size: 37px;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/no-page-found/no-page-found.component.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,eAAe;EACf,MAAM;EACN,OAAO;EACP,aAAa;EACb,YAAY;EACZ,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".page404{\n  margin: 0;\n  padding: 0;\n  position: fixed;\n  top: 0;\n  left: 0;\n  height: 100vh;\n  width: 100vw;\n  background-color: #eceef0;\n  z-index: 10;\n}\n\n.error-template{\n  position: relative;\n  top: calc(50% - 15%);\n  height: 30%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\nh1, h2{\n  color: #212935;\n  font-size: 37px;\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
