// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body_bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -2;
	background: url('bg_logo1.jpg') top right;
	background-size: cover;
	background-repeat: no-repeat;
}

.wrap {
	max-width: 1080px;
	margin: 50px auto 0;
	padding: 1px 10px 50px;
}

.tab_tit {
	margin: 50px 0 30px;
	border-bottom: 2px solid #d2d2d2;
}

.tab_tit h3 {
	color: #d2d2d2
}

.txt_box {
	width: 100%;
	max-height: 50vh;
	overflow: auto;
	border: 1px solid #d2d2d2;
	padding: 30px;
	font-size: 13px;
	color: #d2d2d2;
	background-color: #0f0b0a;
}

p {
	color: #d2d2d2;
	font-size: 13px;
}

h4 {
	margin: 10px 0;
}
`, "",{"version":3,"sources":["webpack://./src/app/layout-kr/components/info/membership-terms/membership-terms.component.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,MAAM;CACN,OAAO;CACP,WAAW;CACX,YAAY;CACZ,WAAW;CACX,yCAAoE;CACpE,sBAAsB;CACtB,4BAA4B;AAC7B;;AAEA;CACC,iBAAiB;CACjB,mBAAmB;CACnB,sBAAsB;AACvB;;AAEA;CACC,mBAAmB;CACnB,gCAAgC;AACjC;;AAEA;CACC;AACD;;AAEA;CACC,WAAW;CACX,gBAAgB;CAChB,cAAc;CACd,yBAAyB;CACzB,aAAa;CACb,eAAe;CACf,cAAc;CACd,yBAAyB;AAC1B;;AAEA;CACC,cAAc;CACd,eAAe;AAChB;;AAEA;CACC,cAAc;AACf","sourcesContent":[".body_bg {\n\tposition: absolute;\n\ttop: 0;\n\tleft: 0;\n\twidth: 100%;\n\theight: 100%;\n\tz-index: -2;\n\tbackground: url(../../../../../assets/images/bg_logo1.jpg) top right;\n\tbackground-size: cover;\n\tbackground-repeat: no-repeat;\n}\n\n.wrap {\n\tmax-width: 1080px;\n\tmargin: 50px auto 0;\n\tpadding: 1px 10px 50px;\n}\n\n.tab_tit {\n\tmargin: 50px 0 30px;\n\tborder-bottom: 2px solid #d2d2d2;\n}\n\n.tab_tit h3 {\n\tcolor: #d2d2d2\n}\n\n.txt_box {\n\twidth: 100%;\n\tmax-height: 50vh;\n\toverflow: auto;\n\tborder: 1px solid #d2d2d2;\n\tpadding: 30px;\n\tfont-size: 13px;\n\tcolor: #d2d2d2;\n\tbackground-color: #0f0b0a;\n}\n\np {\n\tcolor: #d2d2d2;\n\tfont-size: 13px;\n}\n\nh4 {\n\tmargin: 10px 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
