// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    /* background: linear-gradient(45deg, #000000, #434343); */
    background: linear-gradient(45deg, #000000, #434343);
    background: url('bg_logo1.jpg') top right;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .wrap {
    max-width: 1080px;
    margin: 0 auto;
    padding: 1% 5%;
  }

  .container {
    margin-bottom: 30px;
    /* padding: 0 5px; */
  }

  .row {
    width: 100%;
	height: 100%;
	/* padding: 0 10px; */
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
  }

  /*부트스트랩 그리드 safari 대응*/
  .row:before,
  .row:after {
  display: inline-block !important;
  }

  .mypage-header h3 {
    color: white;
    font-size: 2.5em;
  }

  .mypage-header p {
    color: white;
    font-size: 1.5em;
  }

  .admin-sub-menu {
    justify-content: right;
  }

  .admin-sub-menu ul {
    margin: 0;
	padding: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	list-style: none;
  }

  .admin-sub-menu a {
    
  }
`, "",{"version":3,"sources":["webpack://./src/app/layout-kr/components/admin/admin.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,WAAW;IACX,0DAA0D;IAC1D,oDAAoD;IACpD,yCAAiE;IACjE,sBAAsB;IACtB,4BAA4B;EAC9B;;EAEA;IACE,iBAAiB;IACjB,cAAc;IACd,cAAc;EAChB;;EAEA;IACE,mBAAmB;IACnB,oBAAoB;EACtB;;EAEA;IACE,WAAW;CACd,YAAY;CACZ,qBAAqB;CACrB,cAAc;CACd,aAAa;CACb,uBAAuB;CACvB,mBAAmB;EAClB;;EAEA,sBAAsB;EACtB;;EAEA,gCAAgC;EAChC;;EAEA;IACE,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,SAAS;CACZ,UAAU;CACV,aAAa;CACb,mBAAmB;CACnB,mBAAmB;CACnB,gBAAgB;EACf;;EAEA;;EAEA","sourcesContent":[".body_bg {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    z-index: -2;\n    /* background: linear-gradient(45deg, #000000, #434343); */\n    background: linear-gradient(45deg, #000000, #434343);\n    background: url(../../../../assets/images/bg_logo1.jpg) top right;\n    background-size: cover;\n    background-repeat: no-repeat;\n  }\n  \n  .wrap {\n    max-width: 1080px;\n    margin: 0 auto;\n    padding: 1% 5%;\n  }\n\n  .container {\n    margin-bottom: 30px;\n    /* padding: 0 5px; */\n  }\n\n  .row {\n    width: 100%;\n\theight: 100%;\n\t/* padding: 0 10px; */\n\tmargin: 0 auto;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n  }\n\n  /*부트스트랩 그리드 safari 대응*/\n  .row:before,\n  .row:after {\n  display: inline-block !important;\n  }\n\n  .mypage-header h3 {\n    color: white;\n    font-size: 2.5em;\n  }\n\n  .mypage-header p {\n    color: white;\n    font-size: 1.5em;\n  }\n\n  .admin-sub-menu {\n    justify-content: right;\n  }\n\n  .admin-sub-menu ul {\n    margin: 0;\n\tpadding: 0;\n\tdisplay: flex;\n\tflex-direction: row;\n\talign-items: center;\n\tlist-style: none;\n  }\n\n  .admin-sub-menu a {\n    \n  }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
