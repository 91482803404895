// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body_bg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  background: linear-gradient(45deg, #000000, #434343);
  background: url('bg_logo1.jpg') top right;
  background-size: cover;
  background-repeat: no-repeat;
}

.wrap{
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 280px);
}

.text_box{
  color: #d2d2d2;
  border: 1px solid #d2d2d2;
  border-radius: 6px;
  padding: 15px;
  font-size: 14px;
  text-align: left;
}

.text_box p{
  padding: 15px 0;
}

.text_box span{
  color: #f44336;
}

hr{
  background-color: #d2d2d2;
}
`, "",{"version":3,"sources":["webpack://./src/app/layout-kr/components/delete-account/reactivate-account/reactivate-account.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,WAAW;EACX,oDAAoD;EACpD,yCAAoE;EACpE,sBAAsB;EACtB,4BAA4B;AAC9B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,2BAA2B;AAC7B;;AAEA;EACE,cAAc;EACd,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".body_bg{\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  z-index: -2;\n  background: linear-gradient(45deg, #000000, #434343);\n  background: url(../../../../../assets/images/bg_logo1.jpg) top right;\n  background-size: cover;\n  background-repeat: no-repeat;\n}\n\n.wrap{\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: calc(100vh - 280px);\n}\n\n.text_box{\n  color: #d2d2d2;\n  border: 1px solid #d2d2d2;\n  border-radius: 6px;\n  padding: 15px;\n  font-size: 14px;\n  text-align: left;\n}\n\n.text_box p{\n  padding: 15px 0;\n}\n\n.text_box span{\n  color: #f44336;\n}\n\nhr{\n  background-color: #d2d2d2;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
